import Vue from 'vue'
import Vuex from 'vuex'
import actions from './actions'
import getters from './getters'
import dashboard from './modules/dashboard'
import form from './modules/form'
import administradores from './modules/administradores'
import associados from './modules/associados'
import calculos from './modules/calculos'
import eventos from './modules/eventos'
import extratoAssociados from './modules/extratoAssociados'
import extratoFornecedores from './modules/extratoFornecedores'
import fornecedores from './modules/fornecedores'
import gestores from './modules/gestores'
import lancamentoAvulsos from './modules/lancamentoAvulsos'
import lancamentoFaturas from './modules/lancamentoFaturas'
import notificacao from './modules/notificacao'
import regionais from './modules/regionais'
import sempermissao from './modules/semPermissao'
import unidadesNegocio from './modules/unidadesNegocio'
import user from './modules/user'
import userProfile from './modules/userProfile'
import mutations from './mutations'
import state from './states'

Vue.use(Vuex)

export default new Vuex.Store({
  // Making sure that we're doing
  // everything correctly by enabling
  // strict mode in the dev environment.
  namespaced: true,
  // O strict serve para mapear toda modificação feita
  // no estado para fins de debug
  // Ele foi desabilitado devido à necessidade de modificar
  // uma propriedade nested nas regras das ofertas.
  // strict: process.env.NODE_ENV !== 'production',
  state,
  actions,
  mutations,
  getters,
  modules: {
    gestores,
    unidadesNegocio,
    form,
    notificacao,
    associados,
    fornecedores,
    dashboard,
    administradores,
    lancamentoFaturas,
    regionais,
    sempermissao,
    user,
    userProfile,
    calculos,
    eventos,
    extratoAssociados,
    extratoFornecedores,
    lancamentoAvulsos,
  },
})
