const router = function (permissions) {
  return ({
    path: 'associados',
    component: () => import('@/views/Associado/AssociadoModule.vue'),
    meta: { permissions },
    children: [
      {
        path: '/',
        component: () => import('@/views/Associado/AssociadoList.vue'),
        meta: { permissions, title: 'Manutenção de Associados' },
        props: (route) => ({ cnpjs: route.query.cnpjs }),
      },
      {
        path: 'cadastrar',
        component: () => import('@/views/Associado/AssociadoProfile.vue'),
        meta: { permissions, title: 'Cadastrando Associado' },
      },
      {
        path: 'alterar',
        component: () => import('@/views/Associado/AssociadoProfile.vue'),
        meta: { permissions, title: 'Editando Associado' },
      },
    ],
  })
}

export default {
  router,
}
