<template>
  <v-dialog
    v-model="dialog"
    max-width="1320px"
    max-height="800px"
    width="800px"
    persistent
    scrollable
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-form
      v-if="dialog"
      ref="notificaForm"
      lazy-validation
    >
      <v-card>
        <v-toolbar
          color="primary"
          dark
        >
          <v-col
            cols="11.5"
          >
            <v-toolbar-title>
              {{ title + (nome ? ' ' + nome : '') }}
            </v-toolbar-title>
          </v-col>
          <v-btn
            icon
            dark
            @click="cancel"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text
          class="px-2 pt-2 pb-2 py-2"
        >
          <v-card
            :elevation="4"
            height="250px"
            width="782px"
          >
            <v-card-text
              class="px-3 pt-3 pb-3 py-3"
            >
              <v-text-field
                v-model="assunto"
                label="Assunto"
                dense
                :rules="[rules.required,rules.maxLength(60, 'Max. 60 caracteres')]"
                outlined
                rounded
              />
              <v-textarea
                v-model="mensagem"
                no-resize
                label="Mensagem"
                counter
                :rules="[rules.required,rules.maxLength(255, 'Max. 255 caracteres')]"
                outlined
                rounded
              />
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-actions
          class="justify-end"
        >
          <v-btn
            class="text-none text-white"
            color="error"
            rounded
            @click="cancel"
          >
            <v-icon
              dark
              left
            >
              mdi-minus-circle
            </v-icon>
            Cancelar
          </v-btn>
          <v-btn
            color="success"
            class="text-none text-white"
            rounded
            @click="envia(confirma)"
          >
            <v-icon
              dark
              left
            >
              mdi-file-export
            </v-icon>
            Enviar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
  import Swal from 'sweetalert2'
  import { mapMutations, mapState, mapActions } from 'vuex'
  import { mapFields } from 'vuex-map-fields'
  import rules from '../../utils/formRules'
  import { CLOSE, SET_STATE, SUBMIT } from '@/store/modules/notificacao'

  export default {
    name: 'TecnicoNotifica',
    components: {
    },
    props: {
      title: {
        type: String,
        default: '',
      },
    },
    data () {
      return {
        valid: false,
        rules: rules,
      }
    },
    computed: {
      ...mapState('notificacao', ['loading', 'dialog']),
      ...mapFields('notificacao', ['id', 'tipo', 'nome', 'assunto', 'mensagem', 'confirma']),
    },
    created () {
    },
    methods: {
      ...mapMutations('notificacao', [CLOSE, SET_STATE]),
      ...mapActions('notificacao', [SUBMIT]),
      cancel () {
        this.CLOSE()
      },
      envia (confirma) {
        if (!this.$refs.notificaForm.validate()) {
          return null
        }

        if (confirma) {
          Swal.fire({
            title: 'Atenção',
            text: 'Tem certeza que deseja enviar essa notificação para todos?',
            icon: 'question',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            confirmButtonColor: '#109010',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirma',
            cancelButtonText: 'Cancelar',
            focusConfirm: false,
            preConfirm: () => {
              return this.SUBMIT()
            },
          })
        } else {
          return this.SUBMIT()
        }
      },
    },
  }
</script>
