<template>
  <div>
    <v-row class="pa-0">
      <v-col
        cols="12"
        md="6"
      >
        <v-text-field
          v-model="razao_social"
          dense
          autocomplete="nope"
          :rules="[...($optional.razao_social ? [] : [rules.required])]"
          :label="`Razão Social${$optional.razao_social ? '' : '*'}`"
          :disabled="$disabled.razao_social"
          validate-on-blur
          outlined
          rounded
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-text-field
          v-model="nome_fantasia"
          dense
          autocomplete="nope"
          class="no-auto-complete"
          :rules="[...($optional.nome_fantasia ? [] : [rules.required])]"
          :label="`Nome Fantasia${$optional.nome_fantasia ? '' : '*'}`"
          :disabled="$disabled.nome_fantasia"
          validate-on-blur
          outlined
          rounded
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-text-field
          v-model="cnpj"
          v-mask="maskCnpj"
          dense
          autocomplete="nope"
          :rules="[...($optional.cnpj ? [] : [rules.required]), rules.cnpj]"
          :label="`CNPJ${$optional.cnpj ? '' : '*'}`"
          :disabled="$disabled.cnpj"
          validate-on-blur
          outlined
          rounded
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-text-field
          v-model="inscricao_estadual"
          dense
          autocomplete="nope"
          :rules="[...($optional.inscricao_estadual ? [] : [rules.required])]"
          :label="`Inscrição Estadual${$optional.inscricao_estadual ? '' : '*'}`"
          :disabled="$disabled.inscricao_estadual"
          validate-on-blur
          outlined
          rounded
        />
      </v-col>
      <v-col
        v-if="!$exclude.email"
        cols="12"
        md="6"
        style="padding-top: 0px;"
      >
        <v-text-field
          v-model="email"
          dense
          autocomplete="nope"
          :rules="[...($optional.email ? [] : [rules.required]), rules.email]"
          :label="`Email${$optional.email ? '' : '*'}`"
          :disabled="$disabled.email"
          validate-on-blur
          outlined
          rounded
          @input="email = email.toLowerCase()"
        />
      </v-col>
      <slot name="append" />
    </v-row>
  </div>
</template>
<script>
  import { mapFields } from '../../utils/formMapFields'
  import rules from '../../utils/formRules'
  import { required } from 'vuelidate/lib/validators'
  import { validarCNPJ } from '../../utils/validacoes'
  export default {
    name: 'PessoaJuridicaBase',
    props: {
      optional: {
        type: Array,
        default: () => {
          return []
        },
      },
      exclude: {
        type: Array,
        default: () => {
          return []
        },
      },
      notEditable: {
        type: Array,
        default: () => {
          return []
        },
      },
      disabled: {
        type: Array,
        default: () => {
          return []
        },
      },
      storeModule: {
        type: String,
        default: 'form',
      },
    },
    data () {
      return {
        valid: true,
        rules: rules,
        maskCnpj: '##.###.###/####-##',
      }
    },
    computed: {
      ...mapFields({ fields: ['razao_social', 'nome_fantasia', 'cnpj', 'email', 'inscricao_estadual'] }),
      $disabled () {
        const disabledFields = this.disabled
          ? Object.fromEntries(
            this.$fields.map(field => [
              field,
              !!this.disabled.find(e => e === field),
            ]),
          )
          : null
        return disabledFields
      },
    },
    validations () {
      return {
        cnpj: {
          required: this.$optional.cnpj || required,
          formato: v => !v || validarCNPJ(v),
        },
        razao_social: {
          ...(this.$optional.razao_social || required),
        },
        nome_fantasia: {
          ...(this.$optional.nome_fantasia || required),
        },
        email: {
          ...(this.$optional.email || required),
        },
      }
    },
    beforeCreate () {
      this.$fields = ['razao_social', 'nome_fantasia', 'cnpj', 'email', 'inscricao_estadual']
      const { exclude, optional, disabled, notEditable } = this.$options.propsData

      this.$exclude = exclude
        ? Object.fromEntries(
          this.$fields.map(field => {
            return [field, !!exclude.find(e => e === field)]
          }),
        )
        : {}

      this.$optional = optional
        ? Object.fromEntries(
          this.$fields.map(field => {
            return [field, optional.find(e => e === field) ? true : null]
          }),
        )
        : {}

      this.$disabledFields = disabled ? Object.fromEntries(this.$fields.map(field => {
        return [field, disabled.find(e => e === field) ? true : null]
      })) : {}

      this.$notEditable = notEditable
        ? Object.fromEntries(
          this.$fields.map(field => {
            return [field, !!notEditable.find(e => e === field)]
          }),
        )
        : {}
    },
    created () {
      if (this.edit) {
        this.fields = this.$store.state.form.data
      }
    },
    methods: {
      validate () {
        this.$v.$touch()
        return !this.$v.$invalid
      },
    },
  }
</script>
