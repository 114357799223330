<template>
  <v-snackbar
    v-model="snackbar"
    v-bind="$attrs"
    top
    right
    shaped
    elevation="24"
    :color="icon"
    transition="slide-x-reverse-transition"
  >
    <v-col>
      <v-row
        class="mt-1 mb-1"
        justify="space-between"
      >
        <div class="d-flex align-center">
          <v-icon
            v-if="icon == 'error'"
            class="mx-2 pb-1"
          >
            mdi-alpha-x-circle-outline
          </v-icon>
          <v-icon
            v-else-if="icon == 'warning'"
            class="mx-2 pb-1"
          >
            mdi-alert-circle-outline
          </v-icon>
          <v-icon
            v-else-if="icon == 'success'"
            class="mx-2 pb-1"
          >
            mdi-check-circle-outline
          </v-icon>
          <span style="font-size: 16px; font-weight: 500">{{ message }}</span>
        </div>
      </v-row>
    </v-col>
    <template v-slot:action="{ attrs }">
      <v-btn
        class="pb-1"
        small
        icon
        dark
        text
        v-bind="attrs"
        @click="$emit('snackbar',false)"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>
<script>
  export default {
    model: { prop: 'value', event: 'snackbar' },
    props: {
      message: {
        type: String,
        default: '',
      },
      value: {
        type: Boolean,
        default: false,
      },
      icon: {
        type: String,
        default: 'error',
      },
    },
    computed: {
      snackbar: {
        set (value) {
          this.$emit('snackbar', value)
        },
        get () {
          return this.value
        },
      },
    },
  }
</script>
