import api from '@/api/api'
import { getField, updateField } from 'vuex-map-fields'

export const BOOTSTRAP = 'BOOTSTRAP'
export const SET_STATE = 'SET_STATE'
export const LOAD_BATCH = 'LOAD_BATCH'
export const RESET_STATE = 'RESET_STATE'

const getDefaultState = () => {
  return {
    ready: false,
    dashboard: [],
  }
}

const state = getDefaultState()

const getters = {
  getField,
}

const mutations = {
  updateField,
  [SET_STATE] (state, payload) {
    Object.assign(state, payload)
  },
  [RESET_STATE] (state) {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  async [BOOTSTRAP] ({ dispatch, commit }) {
    commit(SET_STATE, { ready: false, dashboard: [] })
    await dispatch(LOAD_BATCH)
    commit(SET_STATE, { ready: true })
  },
  async [LOAD_BATCH] ({ commit }) {
    await api.listEntidade('dashboard').then(
      dashboard => {
        commit(SET_STATE, { dashboard })
      },
    )
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
