import api from '@/api/api'
import { getField, updateField } from 'vuex-map-fields'
import Swal from 'sweetalert2'

export const BOOTSTRAP = 'BOOTSTRAP'
export const SET_STATE = 'SET_STATE'
export const LOAD_BATCH = 'LOAD_BATCH'
export const CLOSE = 'CLOSE'
export const SUBMIT = 'SUBMIT'
export const RESET_STATE = 'RESET_STATE'

const getDefaultState = () => {
  return {
    loading: true,
    dialog: false,
    id: null,
    tipo: null,
    nome: null,
    assunto: null,
    mensagem: null,
    confirma: false,
  }
}

const state = getDefaultState()

const getters = {
  getField,
}

const mutations = {
  updateField,
  [SET_STATE] (state, payload) {
    Object.assign(state, payload)
  },
  [CLOSE] (state) {
    state.dialog = false
  },
  [RESET_STATE] (state) {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  async [BOOTSTRAP] ({ dispatch, commit }, { id, tipo, nome, confirma }) {
    commit(SET_STATE, { loading: true, dialog: false })
    await dispatch(LOAD_BATCH, { id, tipo, nome, confirma })
    commit(SET_STATE, { loading: false, dialog: true })
  },
  async [LOAD_BATCH] ({ commit }, { id, tipo, nome, confirma }) {
    commit(SET_STATE, { id, tipo, nome, assunto: '', mensagem: '', confirma })
  },
  async [SUBMIT] ({ commit, state }) {
    const dados = { from: state.id, tipo: state.tipo, title: state.assunto, mensagem: state.mensagem }
    Swal.fire({
      title: 'Enviando notificação!',
    })
    Swal.showLoading()
    api.cadastrarEntidade(dados, 'notificacao/save_notificacao').then(() => {
      commit('CLOSE')
      Swal.hideLoading()
      Swal.update({
        icon: 'success',
        title: 'Notificação enviada com sucesso!',
      })
    }).catch(err => {
      console.log(err)
      Swal.hideLoading()
      Swal.update({
        icon: 'error',
        title: 'Não foi possível enviar a notificação, favor tentar novamente!',
      })
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
