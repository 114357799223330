@ -1,372 +1,321 @@
<template>
  <v-card
    class="px-6 pt-4"
    outlined
    style="margin-top: 14px !important;border-radius: 16px"
  >
    <v-card-title
      class="pa-0 mb-2"
      style="text-shadow: grey 0.1em 0.1em 0.2em;"
    >
      {{ title }}
    </v-card-title>
    <v-row>
      <v-col
        cols="10"
        md="10"
        sm="8"
      >
        <v-text-field
          v-model="logradouro"
          dense
          outlined
          rounded
          autocomplete="nope"
          :rules="[...($optional.logradouro ? [] : [rules.required])]"
          :label="`Logradouro${$optional.logradouro ? '' : '*'}`"
          :disabled="$disabled.logradouro"
        />
      </v-col>
      <v-col
        cols="2"
        md="2"
        sm="4"
      >
        <v-text-field
          v-model="numero"
          dense
          outlined
          rounded
          autocomplete="nope"
          :rules="[...($optional.numero ? [] : [rules.required])]"
          :label="`Número${$optional.numero ? '' : '*'}`"
          :disabled="$disabled.numero"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-text-field
          v-model="bairro"
          dense
          outlined
          rounded
          autocomplete="nope"
          :rules="[...($optional.bairro ? [] : [rules.required])]"
          :label="`Bairro${$optional.bairro ? '' : '*'}`"
          :disabled="$disabled.bairro"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-text-field
          v-model="complemento"
          dense
          outlined
          rounded
          autocomplete="nope"
          label="Complemento"
          :disabled="$disabled.complemento"
        />
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <v-select
          v-model="pais"
          dense
          outlined
          rounded
          :disabled="$disabled.pais || $asyncComputed.paises.updating"
          :items="paises"
          item-text="nome"
          item-value="id"
          autocomplete="off"
          :rules="[...($optional.pais ? [] : [rules.required])]"
          :label="`País${$optional.pais ? '' : '*'}`"
        />
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <v-autocomplete
          v-model="estado"
          dense
          outlined
          rounded
          :items="estados"
          item-text="nome"
          item-value="id"
          :rules="[...($optional.estado ? [] : [rules.required])]"
          :label="`Estado${$optional.estado ? '' : '*'}`"
          autocomplete="nope"
          :disabled="
            $disabled.pais ||
              !pais ||
              $asyncComputed.paises.updating ||
              $asyncComputed.estados.updating
          "
        />
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-autocomplete
          v-model="cidade"
          dense
          outlined
          rounded
          :items="cidades"
          autocomplete="nope"
          item-text="nome"
          item-value="id"
          :rules="[...($optional.cidade ? [] : [rules.required])]"
          :label="`Cidade${$optional.cidade ? '' : '*'}`"
          :disabled="
            $disabled.cidade ||
              !estado ||
              $asyncComputed.estados.updating ||
              $asyncComputed.cidades.updating
          "
        />
      </v-col>
      <v-col
        cols="4"
        md="2"
        sm="4"
      >
        <v-text-field
          v-model="cep"
          v-mask="maskCep"
          dense
          outlined
          rounded
          autocomplete="nope"
          :rules="[
            ...($optional.cep ? [] : [rules.required]),
            rules.minLength(10),
          ]"
          :label="`CEP${$optional.cep ? '' : '*'}`"
          :disabled="$disabled.cep"
          validate-on-blur
        />
      </v-col>

      <v-col
        v-if="!$exclude.regional"
        cols="8"
        md="12"
        sm="8"
      >
        <v-autocomplete
          v-model="regional"
          dense
          outlined
          rounded
          :items="regionais"
          item-text="nome"
          item-value="id"
          :rules="[...($optional.regional ? [] : [rules.required])]"
          :label="`Regional${$optional.regional ? '' : '*'}`"
          autocomplete="nope"
          :disabled="
            $disabled.pais ||
              !pais ||
              $asyncComputed.regionais.updating"
        >
          <template
            slot="item"
            slot-scope="data"
          >
            <v-container
              class="rounded-lg mx-n2"
              fluid
            >
              <v-row
                dense
                justify="space-between"
              >
                <div>{{ data.item.nome }}</div>
              </v-row>
            </v-container>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
    <slot />
  </v-card>
</template>
<script>
  import rules from '../../utils/formRules'
  import { mapFields } from '../../utils/formMapFields'
  export default {
    name: 'Localizacao',
    props: {
      title: {
        type: String,
        default: () => 'Localização',
      },
      optional: {
        type: Array,
        default: () => {
          return []
        },
      },
      exclude: {
        type: Array,
        default: () => {
          return []
        },
      },
      editable: {
        type: Array,
        default: () => {
          return []
        },
      },
      disabled: {
        type: Array,
        default: () => {
          return []
        },
      },
      storeModule: {
        type: String,
        default: 'form',
      },
    },
    data () {
      return {
        name: null,
        valid: true,
        rules: rules,
        maskCep: '##.###-###',
        paises: [],
        loading: true,
      }
    },
    computed: {
      ...mapFields({
        fields: [
          'pais',
          'estado',
          'cidade',
          'bairro',
          'logradouro',
          'numero',
          'cep',
          'complemento',
          'regional',
        ],
        custom: {
          pais: {
            set (value) {
              if (!this.loading) {
                this.estado = null
              }
              this.$store.commit(`${this.storeModule}/UPDATE_FORM_FIELD`, {
                pais: value,
              })
            },
          },
          estado: {
            set (value) {
              if (!this.loading) {
                this.cidade = null
              }
              this.$store.commit(`${this.storeModule}/UPDATE_FORM_FIELD`, {
                estado: value,
              })
            },
          },
        },
      }),
      $disabled () {
        const disabledFields = this.disabled
          ? Object.fromEntries(
            this.$fields.map(field => [
              field,
              !!this.disabled.find(e => e === field),
            ]),
          )
          : null
        return disabledFields
      },
    },
    asyncComputed: {
      paises: {
        get () {
          return this.api.paises().then(paises => paises)
        },
        default: [],
      },
      estados: {
        get () {
          return this.pais
            ? this.api.estados(this.pais).then(estados => estados)
            : []
        },
        default: [],
      },
      cidades: {
        get () {
          return this.estado && this.pais
            ? this.api.cidades(this.pais, this.estado).then(cidades => cidades)
            : []
        },
        default: [],
      },
      regionais: {
        get () {
          return this.api.regionais().then(regionais => regionais)
        },
        default: [],
      },
    },
    beforeCreate () {
      this.$fields = [
        'pais',
        'estado',
        'cidade',
        'bairro',
        'logradouro',
        'numero',
        'cep',
        'complemento',
        'regional',
      ]
      const { exclude, optional } = this.$options.propsData

      this.$exclude = exclude
        ? Object.fromEntries(
          this.$fields.map(field => {
            return [field, !!exclude.find(e => e === field)]
          }),
        )
        : {}

      this.$optional = optional
        ? Object.fromEntries(
          this.$fields.map(field => {
            return [field, optional.find(e => e === field) ? true : null]
          }),
        )
        : {}
    },
    created () {
      if (this.edit) {
        this.fields = this.$store.state.form.data
      } else {
        this.pais = 1
      }
      this.loading = false
    },
  }
</script>
