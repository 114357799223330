import api from '@/api/api'
import Swal from 'sweetalert2'
import { getField, updateField } from 'vuex-map-fields'
import moment from 'moment'

export const BOOTSTRAP = 'BOOTSTRAP'
export const SET_STATE = 'SET_STATE'
export const SET_EDIT = 'SET_EDIT'
export const LOAD_BATCH = 'LOAD_BATCH'
export const RESET_STATE = 'RESET_STATE'
export const TOGGLE_CALCULO_STATUS = 'TOGGLE_CALCULO_STATUS'
export const PERIODO = 'PERIODO'
export const CALCULA = 'CALCULA'
export const NEW_CALC = 'NEW_CALC'
export const CLOSE = 'CLOSE'

const getDefaultState = () => {
  return {
    dialog: false,
    calculando: false,
    calculos: [],
    loading: false,
    data_inicial: 'from',
    data_final: 'to',
  }
}

const state = getDefaultState()

const getters = {
  getField,
}

const mutations = {
  updateField,
  [SET_STATE] (state, payload) {
    Object.assign(state, payload)
  },
  [RESET_STATE] (state) {
    Object.assign(state, getDefaultState())
  },
  [PERIODO] (state, { data_i, data_f }) {
    state.data_inicial = data_i
    state.data_final = data_f
  },
  [CLOSE] (state) {
    state.dialog = false
  },
}

const actions = {
  async [CALCULA] ({ commit }, status) {
    commit(SET_STATE, { calculando: status })
  },
  async [NEW_CALC] ({ commit }) {
    commit(SET_STATE, { dialog: true })
  },
  async [LOAD_BATCH] ({ commit, state }) {
    await api.listEntidade('calculo/' + moment(state.data_inicial).format('YYYY-MM-01') + '/' + moment(state.data_final).format('YYYY-MM-01')).then(
      calculos => {
        commit(SET_STATE, { calculos })
      },
    )
  },
  async [BOOTSTRAP] ({ dispatch, commit, state }) {
    commit(SET_STATE, { loading: true })
    await dispatch(LOAD_BATCH)
    commit(SET_STATE, { loading: false })
  },
  async [TOGGLE_CALCULO_STATUS] ({ dispatch, state }, calculo) {
    Swal.fire({
      title: 'Atualizando o status do Cálculo!',
    })
    Swal.showLoading()
    api
      .salvarEntidade(calculo, 'calculo/status', calculo.id)
      .then((response) => {
        Swal.hideLoading()
        Swal.update({
          icon: 'success',
          title: `O Cálculo foi ${calculo.status ? 'fechado' : 'aberto'
            } com sucesso!`,
        })
      })
      .catch((err) => {
        console.log(err)
        Swal.hideLoading()
        Swal.update({
          icon: 'error',
          title: `Não foi possível ${calculo.status ? 'fechar' : 'abrir'
            } o Cálculo!`,
        })
      })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
