import Vue from 'vue'
import axios from '../api/axios'
import store from '../store'

const load = () => {
  return new Promise(resolve => {
    axios
      .post('auth/me')
      .then(response => {
        if (response) {
          response.data.role = response.data.roles[0].slug
          Vue.prototype.$user.set(response.data)
          store.commit('SET_USER', response.data)
          return axios.get('/permission', { params: { list: true } })
        } else {
          Vue.prototype.$user.set({ role: 'guest' })
          store.commit('SET_USER', { role: 'guest' })
        }
        resolve()
      })
      .then(response => {
        if (response) {
          const permissions = response.data
          store.commit('SET_PERMISSIONS', permissions)
        }
        resolve()
      })
      .catch(_err => {
        Vue.prototype.$user.set({ role: 'guest' })
        store.commit('SET_USER', { role: 'guest' })
        resolve()
      })
  })
}

const logout = () => {
  return new Promise(resolve => {
    const forceLogout = () => {
      Vue.prototype.$user.set({ role: 'guest' })
      store.commit('SET_USER', { role: 'guest' })
      const mutate = store
      Object.keys(mutate).forEach(item => {
        Object.keys(mutate[item]).forEach(item2 => {
          if (item2.indexOf('RESET_STATE') >= 0) {
            store.commit(item2, { })
          }
        })
      })
      localStorage.clear()
    }
    axios
      .post('auth/logout')
      .then(response => {
        forceLogout()
        resolve()
      })
      .catch(_err => {
        forceLogout()
        resolve()
      })
  })
}

const login = (user, token) => {
  axios.defaults.headers.common.Authorization = 'Bearer ' + token
  localStorage.setItem('user-token', token)
  user.role = user.roles[0].slug

  Vue.prototype.$user.set(user)
  store.commit('SET_USER', user)
}

export { load, login, logout }
