
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import moment from 'moment'
import { getProperty } from './objectUtils'
import { currencyFormatter } from './formatter'

//  TODO: estilos possiveis das colunas
//  para definir o estilo da coluna, basta adicionar o atributo style seguindo o padrão abaixo
//  styleReport: {atributo: valor, atributo: valor, ...}
//  atributos possiveis:
//  font: helvetica|times|courier = helvetica
//  fontStyle: normal|bold|italic|bolditalic = normal
//  overflow: linebreak|ellipsize|visible|hidden = linebreak
//  fillColor: Color? = null
//  textColor: Color? = 20
//  cellWidth: auto|wrap|number = auto
//  minCellWidth: number? = 10
//  minCellHeight: number = 0
//  halign: left|center|right = left
//  valign: top|middle|bottom = top
//  fontSize: number = 10
//  cellPadding: Padding = 10
//  lineColor: Color = 10
//  lineWidth: border = 0
//  formatar para moeda
//  decimal: true
//  status: true
//  dateReference: true
//  titulo: string
//  sum: true - soma a coluna



export class JsonToPdf {
  constructor (data, headers, name) {
    const dataPermitidas = ['YYYY-MM-DD HH:mm:ss', 'DD/MM/YYYY HH:mm:ss', 'YYYY-MM-DD', 'YYYY-MM-DDTHH:mm:ss.SSSSSSZ']
    const spaceLine = 7
    const fontSize = spaceLine + 1
    const fontTitleSize = fontSize * 2
    const margin = { left: 10, right: 10, bottom: 10, top: 28 }

    function addHeader () {
      doc.addImage(window.location.origin + '/img/logo_branca.png', 'png', margin.left, 5, fontSize * 1.8, fontSize * 1.8)
      doc.setFontSize(fontTitleSize)
      doc.text(name, docWidth / 2, 15, { align: 'center' })
      doc.setFontSize(fontSize)
      doc.text(dateNow, docWidth - margin.right, (20), { align: 'right' })
      doc.line(5, (24), docWidth - 5, (24))
    }

    function addFooter (pageNumber) {
      doc.setFontSize(fontSize - 1)
      doc.text(dateNow, margin.left - 5, docHeight - margin.bottom)
      doc.text('Página ' + pageNumber + ' / ' + totalPages, docWidth + 17, docHeight - margin.bottom, { align: 'right' })
    }

      // eslint-disable-next-line new-cap
    var doc = new jsPDF({
      orientation: 'landscape',
    })
    var totalPages = '{total_pages_count_string}'
    const dateNow = (new Date()).toLocaleString()
    const docWidth = doc.internal.pageSize.width
    const docHeight = doc.internal.pageSize.height
    var styles = {}
    var cols = []
    var datas = []
    var total = []
    var sum = {}
    headers.map(header => {
      var col = {}
      if (header.report) {
        if (header.styleReport) {
          styles[header.value] = header.styleReport
          if (header.styleReport.sum) {
            sum[header.value] = 0
          }
        }
        col.dataKey = header.value
        col.header = header.text
        cols.push(col)
      }
    })
    var rows = []
    data.map(item => {
      var row = {}
      headers.map(header => {
        if (header.report) {
          let hv = ''
          if (header.report && moment(getProperty(item, header.value), dataPermitidas, true).isValid()) {
            hv = moment(getProperty(item, header.value)).format('DD/MM/YYYY')
            datas.push(header.value)
          } else {
            hv = getProperty(item, header.value)
            if (header.value in sum) {
              sum[header.value] += parseFloat(hv)
            }
          }
          row[header.value] = hv
        }
      })
      rows.push(row)
    })
    if (Object.keys(sum).length > 0) {
      rows.push(sum)
    }
    doc.autoTable({
      theme: 'plain',
      headStyles: {
        textColor: '#FFFFFF',
        fillColor: '#1E88E5',
      },
      columns: cols,
      columnStyles: styles,
      body: rows,
      foot: total,
      startY: 27,
      pageBreak: 'auto',
      margin: { left: 5, top: margin.top, bottom: (margin.bottom + 10), right: 5 },
      didParseCell: function (data) {
        if (data.cell.styles.sum && (data.table.body).length - 1 === data.row.index) {
          data.cell.styles.fontStyle = 'bold'
          if (parseFloat(data.cell.text) < 0) {
            data.cell.styles.textColor = '#FF0000'
            data.cell.text = (Math.abs(parseFloat(data.cell.text))).toString()
          } else if (isNaN(parseFloat(data.cell.text))) {
            data.cell.text = '0'
          }
        }
        if (data.cell.styles.decimal && data.row.section === 'body' && data.cell.text[0] !== '') {
          data.cell.text = 'R$ ' + currencyFormatter(data.cell.text)
        }
        if (data.cell.styles.status) {
          if (parseInt(data.cell.text) === 1) {
            data.cell.text = 'Ativo'
          }
          if (parseInt(data.cell.text[0]) === 0 || data.cell.text[0] === 'false') {
            data.cell.text = 'Inativo'
          }
        }
        if (data.cell.styles.dateReference && data.cell.text[0] !== '' && data.row.section === 'body') {
          const partesDaData = data.cell.text[0].split('/')
          data.cell.text = partesDaData[1] + '/' + partesDaData[2]
        }
        if (data.cell.styles.titulo && data.row.section === 'head') {
          data.cell.text = data.cell.styles.titulo
        }
      },
      didDrawPage: (data) => {
        addHeader()
        addFooter(data.pageNumber)
      },
    })
  if (typeof doc.putTotalPages === 'function') {
    doc.putTotalPages(totalPages)
  }
    doc.save(name + moment().format('DD-MM-YYYY-HH_mm') + '.pdf')
  }
}
