export class UnidadeNegocio {
  constructor({
    id,
    cnpj,
    inscricao_estadual,
    cod_centro,
    nome_fantasia,
    email,
    email_adm_clube,
    razao_social,
    gestor_id,
    regional,
    telefones,
    bairro,
    cep,
    cidade,
    complemento,
    estado,
    numero,
    pais,
    logradouro,
  } = {
      id: '',
      cnpj: '',
      inscricao_estadual: '',
      cod_centro: '',
      nome_fantasia: '',
      email: '',
      email_adm_clube: '',
      razao_social: '',
      gestor_id: '',
      regional: '',
      telefones: [],
      bairro: '',
      cep: '',
      cidade: '',
      complemento: '',
      estado: '',
      numero: '',
      pais: '',
      logradouro: '',
    }) {
    this.id = id
    this.cnpj = cnpj
    this.inscricao_estadual = inscricao_estadual
    this.cod_centro = cod_centro
    this.nome_fantasia = nome_fantasia
    this.email = email
    this.email_adm_clube = email_adm_clube
    this.razao_social = razao_social
    this.gestor_id = gestor_id
    this.regional = regional
    this.telefones = telefones
    this.bairro = bairro
    this.cep = cep
    this.cidade = cidade
    this.complemento = complemento
    this.estado = estado
    this.numero = numero
    this.pais = pais
    this.logradouro = logradouro
  }
}

export function createUnidadeNegocio (data) {
  return Object.freeze(new UnidadeNegocio(data))
}
