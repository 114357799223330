const permissions = [
  {
    role: 'guest',
    access: true,
  },
  {
    role: 'associado',
    access: false,
    redirect: 'associado',
  },
  {
    role: 'fornecedor',
    access: false,
    redirect: 'fornecedor',
  },
  {
    role: 'gestor',
    access: false,
    redirect: 'gestor',
  },
  {
    role: 'admin',
    access: false,
    redirect: 'admin',
  },
]

export default [
  {
    /* ACESSO ADMINISTRADOR */
    name: 'verifyMail',
    path: '/verifyMail/:token',
    component: () => import('@/views/MailConfirmation/MailConfirmation'),
    meta: { permissions },
  },
  {
    /* ACESSO ADMINISTRADOR */
    name: 'resetPassword',
    path: '/resetPassword/:token',
    component: () => import('@/views/ResetPassword/ResetPassword'),
    meta: { permissions },
  },
]
