const router = function (permissions) {
  return ({
    path: 'extratoFornecedor',
    component: () => import('@/views/ExtratoFornecedor/ExtratoFornecedorModule.vue'),
    meta: { permissions },
    children: [
      {
        path: '/',
        component: () => import('@/views/ExtratoFornecedor/ExtratoFornecedorList.vue'),
        meta: { permissions, title: 'Consulta Extrato dos Fornecedores' },
      },
    ],
  })
}

export default {
  router,
}
