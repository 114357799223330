import api from '@/api/api'
import Swal from 'sweetalert2'
import { getField, updateField } from 'vuex-map-fields'

export const BOOTSTRAP = 'BOOTSTRAP'
export const SET_STATE = 'SET_STATE'
export const SET_EDIT = 'SET_EDIT'
export const LOAD_BATCH = 'LOAD_BATCH'
export const INATIVAR_FORNECEDOR = 'INATIVAR_FORNECEDOR'
export const TOGGLE_FORNECEDOR_STATUS = 'TOGGLE_FORNECEDOR_STATUS'
export const RESET_STATE = 'RESET_STATE'

const getDefaultState = () => {
  return {
    fornecedores: null,
    loading: false,
  }
}

const state = getDefaultState()

const getters = {
  getField,
}

const mutations = {
  updateField,
  [SET_STATE] (state, payload) {
    Object.assign(state, payload)
  },
  [RESET_STATE] (state) {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  async [LOAD_BATCH] ({ commit, state }) {
    await api.listEntidade('fornecedor').then(
      data => {
        const fornecedores = data.map(({ cpf, cnpj, razao_social, nome, ...fornecedor }) => ({
          ...fornecedor,
          cpf_cnpj: cpf || cnpj,
          nome_razao_social: nome || razao_social,
        }))
        commit(SET_STATE, { fornecedores })
      },
    )
  },
  async [BOOTSTRAP] ({ dispatch, commit, state }) {
    commit(SET_STATE, { loading: true })
    await dispatch(LOAD_BATCH)
    commit(SET_STATE, { loading: false })
  },
  async [INATIVAR_FORNECEDOR] ({ dispatch, commit, state }, fornecedor) {
    Swal.fire({
      title: 'Tem certeza que deseja excluir o Fornecedor?',
      icon: 'question',
      showCancelButton: true,
      showLoaderOnConfirm: true,
      confirmButtonColor: '#109010',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      focusConfirm: false,
      preConfirm: () => {
        return api.inativarEntidade('fornecedor', fornecedor.id).then(
          result => result,
          (error) => {
            Swal.fire({
              icon: 'warning',
              title: 'Atenção!',
              html: error.message
                ? error.message
                : 'Não foi possivel excluir o Fornecedor!',
            })
          },
        )
      },
    }).then(result => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'O Fornecedor foi excluído com sucesso!',
          icon: 'success',
          showConfirmButton: false,
          timer: 2500,
        })
        dispatch(BOOTSTRAP)
      }
    })
  },
  async [TOGGLE_FORNECEDOR_STATUS] ({ dispatch, state }, fornecedor) {
    Swal.fire({
      title: 'Atualizando o status do Fornecedor!',
    })
    Swal.showLoading()
    api
      .salvarEntidade(fornecedor, 'fornecedor/status', fornecedor.id)
      .then((response) => {
        Swal.hideLoading()
        Swal.update({
          icon: 'success',
          title: `O Fornecedor foi ${fornecedor.status == 1 ? 'ativado' : 'inativado'
            } com sucesso!`,
        })
      })
      .catch((err) => {
        fornecedor.status = fornecedor.status
        console.log(err)
        Swal.hideLoading()
        Swal.update({
          icon: 'error',
          title: `Não foi possível ${fornecedor.status == 0 ? 'ativar' : 'inativar'
            } o Fornecedor!`,
        })
      })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
