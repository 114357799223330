import api from '@/api/api'
import Swal from 'sweetalert2'
import { getField, updateField } from 'vuex-map-fields'

export const BOOTSTRAP = 'BOOTSTRAP'
export const SET_STATE = 'SET_STATE'
export const SET_EDIT = 'SET_EDIT'
export const LOAD_GESTORES = 'LOAD_GESTORES'
export const DELETE_GESTOR = 'DELETE_GESTOR'
export const RESET_STATE = 'RESET_STATE'

const getDefaultState = () => {
  return {
    gestores: null,
    ready: false,
  }
}

const state = getDefaultState()

const getters = {
  getField,
}

const mutations = {
  updateField,
  [SET_STATE] (state, payload) {
    Object.assign(state, payload)
  },
  [RESET_STATE] (state) {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  async [LOAD_GESTORES] ({ commit, state }) {
    commit(SET_STATE, { ready: false })
    await api.listEntidade('gestor').then(
      gestores => {
        commit(SET_STATE, { gestores, ready: true })
      },
    )
  },
  async [BOOTSTRAP] ({ dispatch, commit, state }) {
    await dispatch(LOAD_GESTORES)
  },
  async [DELETE_GESTOR] ({ dispatch, commit, state }, gestor) {
    const index = state.gestores.findIndex(c => c.id === gestor.id)

      Swal.fire({
        title: 'Tem certeza que deseja excluir o Gestor?',
        icon: 'question',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        confirmButtonColor: '#109010',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Excluir',
        cancelButtonText: 'Cancelar',
        reverseButtons: true,
        focusConfirm: false,
        preConfirm: () => {
          return api.inativarEntidade('gestor', gestor.id).then(
            result => result,
            () => {
              Swal.fire({
                icon: 'error',
                title: 'Ocorreu algum erro!',
                text: 'Não foi possivel excluir o Gestor!',
              })
            },
          )
        },
      }).then(result => {
        if (result.isConfirmed) {
          Swal.fire({
            title: 'O Gestor foi excluído com sucesso!',
            icon: 'success',
            showConfirmButton: false,
            timer: 2500,
          })
          state.gestores.splice(index, 1)
          api.listEntidade('gestor').then(result => {
            state.gestores = result
          })
        }
      })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
