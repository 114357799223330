const router = function (permissions) {
  return ({
    path: 'eventos',
    component: () => import('@/views/Evento/EventoModule.vue'),
    meta: { permissions },
    children: [
      {
        path: '/',
        component: () => import('@/views/Evento/EventoList.vue'),
        meta: { permissions, title: 'Manutenção de Eventos de Cálculo' },
      },
      {
        path: 'cadastrar',
        component: () => import('@/views/Evento/EventoProfile.vue'),
        meta: { permissions, title: 'Cadastrando Evento de Cálculo' },
      },
      {
        path: 'alterar',
        component: () => import('@/views/Evento/EventoProfile.vue'),
        meta: { permissions, title: 'Editando Evento de Cálculo' },
      },
    ],
  })
}

export default {
  router,
}
