const router = function (permissions) {
  return ({
    path: 'calculos',
    component: () => import('@/views/Calculo/CalculoModule.vue'),
    meta: { permissions },
    children: [
      {
        path: '/',
        component: () => import('@/views/Calculo/CalculoList.vue'),
        meta: { permissions, title: 'Manutenção do Cálculo Mensal' },
      },
    ],
  })
}

export default {
  router,
}
