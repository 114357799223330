<template>
  <v-row>
    <v-col
      cols="12"
      class="pt-0 mb-0 pb-0"
    >
      <h3
        style="text-align: left;
                     line-height: normal;
                     margin: 0px;
                     color: rgb(102, 102, 102);"
      >
        Requisitos de senha
      </h3>
      <p
        style="color: #808080;"
        class="mb-0 text-subtitle-1"
      >
        Por favor, siga este guia para uma senha forte:
      </p>
      <li
        :style="`font-size: 13px !important;font-weight: bold; color: ${ upDow ? '#4CAF50' : '#808080' }`"
        class="mb-0 text-subtitle-1"
      >
        <span>
          Use letras maiúscula e minúsculas
          <v-icon
            v-if="upDow"
            small
            color="#4CAF50"
          >
            mdi-check-bold
          </v-icon>
        </span>
      </li>
      <li
        :style="`font-size: 13px !important;font-weight: bold; color: ${ carNum ? '#4CAF50' : '#808080' }`"
        class="mb-0 text-subtitle-1"
      >
        <span>
          Um número (2 são recomendados)
          <v-icon
            v-if="carNum"
            small
            color="#4CAF50"
          >
            mdi-check-bold
          </v-icon>
        </span>
      </li>
      <li
        :style="`font-size: 13px !important;font-weight: bold; color: ${ carEsp ? '#4CAF50' : '#808080' }`"
        class="mb-0 text-subtitle-1"
      >
        <span>
          Um caracter especial
          <v-icon
            v-if="carEsp"
            small
            color="#4CAF50"
          >
            mdi-check-bold
          </v-icon>
        </span>
      </li>
      <li
        :style="`font-size: 13px !important;font-weight: bold; color: ${ minCar ? '#4CAF50' : '#808080' }`"
        class="mb-0 text-subtitle-1"
      >
        <span>
          Mínimo de 8 caracteres
          <v-icon
            v-if="minCar"
            small
            color="#4CAF50"
          >
            mdi-check-bold
          </v-icon>

        </span>
      </li>
    </v-col>
    <v-col
      v-if="withCurrent"
      cols="12"
      :md="stackedLayout ? 12 : 4"
    >
      <v-text-field
        v-if="inputOutlined"
        v-model="currentPassword"
        outlined
        rounded
        dense
        :append-icon="showCurrent ? 'mdi-eye' : 'mdi-eye-off'"
        :type="showCurrent ? 'text' : 'password'"
        label="Senha atual"
        :rules="rulesCurrentPassword"
        autocomplete="new-password"
        validate-on-blur

        @click:append="showCurrent = !showCurrent"
      />
      <v-text-field
        v-else
        v-model="currentPassword"
        :append-icon="showCurrent ? 'mdi-eye' : 'mdi-eye-off'"
        :type="showCurrent ? 'text' : 'password'"
        label="Senha atual"
        :rules="rulesCurrentPassword"
        autocomplete="new-password"
        validate-on-blur
        dense
        filled
        rounded
        @click:append="showCurrent = !showCurrent"
      />
    </v-col>
    <v-col
      cols="12"
      :md="stackedLayout ? 12 : 4"
    >
      <v-text-field
        v-if="inputOutlined"
        v-model="newPassword"
        outlined
        rounded
        dense
        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
        :rules="rulesNewPassword"
        :type="show ? 'text' : 'password'"
        :label="labelSenha"
        validate-on-blur
        autocomplete="new-password"
        @click:append="show = !show"
      />
      <v-text-field
        v-else
        v-model="newPassword"
        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
        :rules="rulesNewPassword"
        :type="show ? 'text' : 'password'"
        :label="labelSenha"
        validate-on-blur
        autocomplete="new-password"
        counter
        dense
        filled
        rounded
        @click:append="show = !show"
        @keyup="validaSenha"
      />
    </v-col>
    <v-col
      v-if="withConfirmation"
      cols="12"
      :md="stackedLayout ? 12 : 4"
    >
      <v-text-field
        v-if="inputOutlined"
        v-model="passwordConfirm"
        outlined
        dense
        rounded
        :append-icon="showConfirmation ? 'mdi-eye' : 'mdi-eye-off'"
        :rules="rulesPasswordConfirm"
        :type="showConfirmation ? 'text' : 'password'"
        label="Confirmação de senha"
        validate-on-blur
        autocomplete="new-password"
        counter
        @click:append="showConfirmation = !showConfirmation"
      />
      <v-text-field
        v-else
        v-model="passwordConfirm"
        :append-icon="showConfirmation ? 'mdi-eye' : 'mdi-eye-off'"
        :rules="rulesPasswordConfirm"
        :type="showConfirmation ? 'text' : 'password'"
        label="Confirmação de senha"
        validate-on-blur
        autocomplete="new-password"
        counter
        dense
        filled
        rounded
        @click:append="showConfirmation = !showConfirmation"
      />
    </v-col>
  </v-row>
</template>
<script>
  import rules from '../../utils/formRules'
  import { mapFields } from '../../utils/formMapFields'
  export default {
    name: 'Password',
    props: {
      minLength: {
        type: Number,
        default: () => 8,
      },
      withConfirmation: {
        type: Boolean,
        default: true,
      },
      withCurrent: {
        type: Boolean,
        default: false,
      },
      storeModule: {
        type: String,
        default: 'form',
      },
      labelSenha: {
        type: String,
        default: 'Senha',
      },
      stackedLayout: {
        type: Boolean,
        default: false,
      },
      inputOutlined: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        show: false,
        showConfirmation: false,
        showCurrent: false,
        name: null,
        valid: true,
        rules: rules,
        upDow: false,
        carEsp: false,
        carNum: false,
        minCar: false,
      }
    },
    computed: {
      ...mapFields({
        fields: ['newPassword', 'passwordConfirm', 'currentPassword'],
      }),
      rulesCurrentPassword () {
        return [
          ...(this.withCurrent
            ? [
              rules.requiredIf(
                this.newPassword,
                '* Por favor, informe a senha atual',
              ),
              rules.requiredIf(
                this.passwordConfirm,
                '* Por favor, informe a senha atual',
              ),
            ]
            : []),
        ]
      },
      rulesNewPassword () {
        return [
          rules.match(this.passwordConfirm, 'As senhas são diferentes.'),
          rules.minLength(this.minLength, `Mínimo ${this.minLength} caracteres.`),
          ...(this.withCurrent
            ? [
              rules.requiredIf(
                this.currentPassword,
                'Por favor, informe a nova senha',
              ),
            ]
            : []),
        ]
      },
      rulesPasswordConfirm () {
        return [
          rules.minLength(this.minLength, `Mínimo ${this.minLength} caracteres.`),
          rules.match(this.newPassword, 'As senhas são diferentes.'),
          ...(this.withCurrent
            ? [
              rules.requiredIf(
                this.currentPassword,
                'Por favor, informe a nova senha',
              ),
            ]
            : []),
        ]
      },
    },
    methods: {
      validaSenha (evento) {
        const value = evento.target.value
        if ((/[a-z]/gm.test(value)) && (/[A-Z]/gm.test(value))) {
          this.upDow = true
        } else {
          this.upDow = false
        }
        if (/[0-9]/gm.test(value)) {
          this.carNum = true
        } else {
          this.carNum = false
        }
        if (/[!@#$%*()_+^&{}}:;?.]/gm.test(value)) {
          this.carEsp = true
        } else {
          this.carEsp = false
        }
        if (value.length >= 8) {
          this.minCar = true
        } else {
          this.minCar = false
        }
      },
    },
  }
</script>
