import { getField, updateField } from 'vuex-map-fields'
import api from '@/api/api'
import Swal from 'sweetalert2'
import { profileTransformer } from '@/utils/transformers'

export const SET_STATE = 'SET_STATE'
export const BOOTSTRAP_PROFILE = 'BOOTSTRAP_PROFILE'
export const LOAD_NOTIFICATIONS = 'LOAD_NOTIFICATIONS'
export const LOAD_UNIDADES = 'LOAD_UNIDADES'
export const LOAD_LANCAMENTO = 'LOAD_LANCAMENTO'
export const LOAD_ROLES = 'LOAD_ROLES'
export const SUBMIT = 'SUBMIT'
export const UPDPASSWORD = 'UPDPASSWORD'
export const RESET_STATE = 'RESET_STATE'

const getDefaultState = () => {
  return {
    telefones: [
      { tipo: 'Comercial', format: 'com', title: 'Telefone 1' },
      { tipo: 'Comercial', format: 'com', title: 'Telefone 2' },
    ],
    loading: true,
    fornecedores: null,
    associados: null,
    isEditing: false,
  }
}

const state = getDefaultState()

const getters = {
  getField,
}

const mutations = {
  updateField,
  [SET_STATE] (state, payload) {
    Object.assign(state, payload)
  },
  [RESET_STATE] (state) {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  async [BOOTSTRAP_PROFILE] ({ dispatch, commit, state }) {
    commit(SET_STATE, { loading: true })
    api.getProfile().then(response => {
      dispatch('form/BOOTSTRAP_FORM', { model: profileTransformer(response) }, { root: true })
      commit(SET_STATE, { loading: false, isEditing: true })
    })
  },
  async [LOAD_NOTIFICATIONS] ({ commit, rootState }) {
    if (rootState.user.roles[0].slug === 'gestor') {
      const notifications = (await api.listEntidade(`${rootState.user.roles[0].slug}/notification`))

      commit(SET_STATE, { fornecedores: notifications[0], associados: notifications[1] })
    } else {
      commit(SET_STATE, { fornecedores: null, associados: null })
    }
  },
  async [UPDPASSWORD] ({ rootState }, { router, user }) {
    const { data, ...formSenha } = rootState.form.data

    Swal.fire({
      title: 'Enviando dados!',
      timerProgressBar: true,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading()
      },
      didOpen: () => {
        api.updatePassword(formSenha).then(
          data => {
            Swal.fire({
              title: 'Senha atualizado com sucesso!',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500,
            })
            router.push({
              path: `/${user.get().role}/dashboard`,
            })
          },
          ({ errors = null, message }) => {
            Swal.fire({
              icon: 'error',
              title: 'Erro',
              text: errors || message,
            })
          },
        )
      },
    })
  },
  async [SUBMIT] ({ dispatch, state, rootState }, { router, user }) {
    const { data, ...form } = rootState.form.data

    if (form.data_nascimento) {
      const [day, month, year] = form.data_nascimento.split('/')
      form.data_nascimento = year + '-' + month + '-' + day
    }
    Swal.fire({
      title: 'Enviando dados!',
      timerProgressBar: true,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading()
      },
      didOpen: () => {
        api.updateProfile(form).then(
          data => {
            Swal.fire({
              title: 'Perfil atualizado com sucesso!',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500,
            })
            router.push({
              path: `/${user.get().role}/dashboard`,
            })
          },
          ({ errors = null, message }) => {
            Swal.fire({
              icon: 'error',
              title: 'Erro',
              text: errors || message,
            })
          },
        )
      },
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
