import api from '@/api/api'
import Swal from 'sweetalert2'
import { getField, updateField } from 'vuex-map-fields'

export const BOOTSTRAP = 'BOOTSTRAP'
export const SET_STATE = 'SET_STATE'
export const SET_EDIT = 'SET_EDIT'
export const LOAD_LANCAMENTO_FATURAMENTO = 'LOAD_LANCAMENTO_FATURAMENTO'
export const DELETE_LANCAMENTO_FATURAMENTO = 'DELETE_LANCAMENTO_FATURAMENTO'
export const RESET_STATE = 'RESET_STATE'
export const NEW_LANCAMENTO = 'NEW_LANCAMENTO'
export const EDIT_LANCAMENTO = 'EDIT_LANCAMENTO'
export const PERIODO = 'PERIODO'

const getDefaultState = () => {
  return {
    lancamentos: null,
    loading: false,
    data_inicial: 'from',
    data_final: 'to',
  }
}

const state = getDefaultState()

const getters = {
  getField,
}

const mutations = {
  updateField,
  [SET_STATE] (state, payload) {
    Object.assign(state, payload)
  },
  [RESET_STATE] (state) {
    Object.assign(state, getDefaultState())
  },
  [PERIODO] (state, { data_i, data_f }) {
    state.data_inicial = data_i
    state.data_final = data_f
  },
}

const actions = {
  async [LOAD_LANCAMENTO_FATURAMENTO] ({ commit, state }) {
    await api.listEntidade('lancamentoFaturamento/' + state.data_inicial + '/' + state.data_final).then(
      lancamentos => {
        commit(SET_STATE, { lancamentos })
      },
    )
  },
  [NEW_LANCAMENTO] ({ dispatch }) {
    dispatch('form/lancamentoFatura/BOOTSTRAP_PROFILE', { id: null }, { root: true })
  },
  [EDIT_LANCAMENTO] ({ dispatch }, lancamentos) {
    dispatch('form/lancamentoFatura/BOOTSTRAP_PROFILE', { id: lancamentos.id }, { root: true })
  },
  async [BOOTSTRAP] ({ dispatch, commit, state }) {
    commit(SET_STATE, { loading: true })
    await dispatch(LOAD_LANCAMENTO_FATURAMENTO)
    commit(SET_STATE, { loading: false })
  },
  async [DELETE_LANCAMENTO_FATURAMENTO] ({ dispatch, commit, state }, lancamentos) {
    const index = state.lancamentos.findIndex(c => c.id === lancamentos.id)
    Swal.fire({
      title: 'Tem certeza que deseja excluir o Lançamento?',
      icon: 'question',
      showCancelButton: true,
      showLoaderOnConfirm: true,
      confirmButtonColor: '#109010',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      focusConfirm: false,
      preConfirm: () => {
        return api.inativarEntidade('lancamentoFaturamento', lancamentos.id).then(
          result => result,
          error => {
            Swal.fire({
              icon: 'error',
              title: 'Atenção',
              html: error.errors ? error.errors : error.message,
            })
          },
        )
      },
    }).then(result => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'O Lançamento foi excluído com sucesso!',
          icon: 'success',
          showConfirmButton: false,
          timer: 2500,
        })
        state.lancamentos.splice(index, 1)
        api.listEntidade('lancamentoFaturamento/' + state.data_inicial + '/' + state.data_final).then(
          lancamentos => {
            commit(SET_STATE, { lancamentos })
          },
        )
      }
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
