import api from '@/api/api'
import { getField, updateField } from 'vuex-map-fields'
import moment from 'moment'

export const BOOTSTRAP = 'BOOTSTRAP'
export const SET_STATE = 'SET_STATE'
export const SET_EDIT = 'SET_EDIT'
export const LOAD_BATCH = 'LOAD_BATCH'
export const RESET_STATE = 'RESET_STATE'
export const PERIODO = 'PERIODO'
export const CLOSE = 'CLOSE'
export const OPEN = 'OPEN'

const getDefaultState = () => {
  return {
    dialog: false,
    extratoAssociados: [],
    loading: false,
    data_i: 'from',
  }
}

const state = getDefaultState()

const getters = {
  getField,
}

const mutations = {
  updateField,
  [SET_STATE] (state, payload) {
    Object.assign(state, payload)
  },
  [RESET_STATE] (state) {
    Object.assign(state, getDefaultState())
  },
  [PERIODO] (state, { data_i }) {
    state.data_i = data_i
  },
  [CLOSE] (state) {
    state.dialog = false
  },
}

const actions = {
  async [LOAD_BATCH] ({ commit, state }) {
    await api.listEntidade('calculo/extrato/associado/' + moment(state.data_i).format('YYYY-MM-01')).then(
      extratoAssociados => {
        commit(SET_STATE, { extratoAssociados })
      },
    )
  },
  async [BOOTSTRAP] ({ dispatch, commit, state }) {
    commit(SET_STATE, { loading: true })
    await dispatch(LOAD_BATCH)
    commit(SET_STATE, { loading: false })
  },
  async [OPEN] ({ commit }) {
    commit(SET_STATE, { dialog: true })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
