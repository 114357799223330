import api from '@/api/api'
import Swal from 'sweetalert2'
import { getField, updateField } from 'vuex-map-fields'

export const BOOTSTRAP = 'BOOTSTRAP'
export const SET_STATE = 'SET_STATE'
export const SET_EDIT = 'SET_EDIT'
export const LOAD_BATCH = 'LOAD_BATCH'
export const RESET_STATE = 'RESET_STATE'
export const INATIVAR_EVENTO = 'INATIVAR_EVENTO'
export const TOGGLE_EVENTO_STATUS = 'TOGGLE_EVENTO_STATUS'

const getDefaultState = () => {
  return {
    loadeventos: null,
    loading: false,
  }
}

const state = getDefaultState()

const getters = {
  getField,
}

const mutations = {
  updateField,
  [SET_STATE] (state, payload) {
    Object.assign(state, payload)
  },
  [RESET_STATE] (state) {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  async [LOAD_BATCH] ({ commit, state }) {
    await api.listEntidade('evento').then(
      loadeventos => {
        commit(SET_STATE, { loadeventos })
      },
    )
  },
  async [BOOTSTRAP] ({ dispatch, commit, state }) {
    commit(SET_STATE, { loading: true })
    await dispatch(LOAD_BATCH)
    commit(SET_STATE, { loading: false })
  },
  async [INATIVAR_EVENTO] ({ dispatch, commit, state }, evento) {
    Swal.fire({
      title: 'Exclusão',
      text: 'Tem certeza que deseja excluir esse Evento?',
      icon: 'question',
      showCancelButton: true,
      showLoaderOnConfirm: true,
      confirmButtonColor: '#109010',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      focusConfirm: false,
      preConfirm: () => {
        return api.inativarEntidade('evento', evento.id).then(
          result => result,
          (error) => {
            Swal.fire({
              icon: 'warning',
              title: 'Atenção!',
              text: error.message
                ? error.message
                : 'Não foi possivel excluir o Evento!',
            })
          },
        )
      },
    }).then(result => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'o Evento foi excluído com sucesso!',
          icon: 'success',
          showConfirmButton: false,
          timer: 2500,
        })
        dispatch(BOOTSTRAP)
      }
    })
  },
  async [TOGGLE_EVENTO_STATUS] ({ dispatch, state }, evento) {
    Swal.fire({
      title: 'Atualizando o status do Evento!',
    })
    Swal.showLoading()
    api
      .salvarEntidade(evento, 'evento/status', evento.id)
      .then((response) => {
        Swal.hideLoading()
        Swal.update({
          icon: 'success',
          title: `O Evento foi ${evento.status == 1 ? 'ativado' : 'inativado'
            } com sucesso!`,
        })
      })
      .catch((err) => {
        console.log(err)
        Swal.hideLoading()
        Swal.update({
          icon: 'error',
          title: `Não foi possível ${evento.status === 0 ? 'ativar' : 'inativar'
            } o Evento!`,
        })
      })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
