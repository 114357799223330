export const currencyFormatter = (number, maximo) => {
  if (maximo === undefined) {
    maximo = 2
  }
  return new Intl.NumberFormat('pt-BR', { minimumFractionDigits: maximo, maximumFractionDigits: maximo }).format(String(number))
}

export const dateFormatter = (string) => {
  return string.substring(8, 10) + '/' + string.substring(5, 7) + '/' + string.substring(0, 4)
}

export const datetimeFormatter = (string) => {
  return string.substring(8, 10) + '/' + string.substring(5, 7) + '/' + string.substring(0, 4) + ' ' + string.substring(11, 13) + ':' + string.substring(14, 16) + ':' + string.substring(17, 19)
}

export const normalizeStr = (string) => {
  return String(string).normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()
}

export const formatterCPFCNPJ = (cpfCnpj) => {
  // Remove todos os caracteres não numéricos
  cpfCnpj = cpfCnpj.replace(/\D/g, '')

  if (cpfCnpj.length === 11) {
    return cpfCnpj.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
  } else if (cpfCnpj.length === 14) {
    return cpfCnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
  } else {
    return null
  }
}
