import profile from './profile'

const permissions = [
  {
    role: 'admin',
    access: false,
    redirect: 'admin',
  },
  {
    role: 'gestor',
    access: false,
    redirect: 'gestor',
  },
  {
    role: 'guest',
    access: false,
    redirect: 'login',
  },
]

/* ACESSO GESTOR */
export default {
  name: 'associado',
  path: '/associado',
  redirect: '/associado/dashboard',
  component: () => import('@/views/Index'),
  meta: { requiresAuth: true, permissions },
  children: [
    {
      path: 'dashboard',
      component: () => import('@/views/dashboard/DashboardAssociado'),
      meta: { permissions, title: 'Início' },
    },
    profile.router(permissions),
  ],
}
