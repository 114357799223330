import profile from './profile'

const permissions = [
  {
    role: 'admin',
    access: false,
    redirect: 'admin',
  },
  {
    role: 'gestor',
    access: false,
    redirect: 'gestor',
  },
  {
    role: 'guest',
    access: false,
    redirect: 'login',
  },
]

/* ACESSO FORNECEDOR */
export default {
  name: 'fornecedor',
  path: '/fornecedor',
  redirect: '/fornecedor/dashboard',
  component: () => import('@/views/Index'),
  meta: { requiresAuth: true, permissions },
  children: [
    {
      path: 'dashboard',
      component: () => import('@/views/dashboard/DashboardFornecedor'),
      meta: { permissions, title: 'Início' },
    },
    profile.router(permissions),
  ],
}
