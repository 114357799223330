const router = function (permissions) {
  return ({
    path: 'lancamentoAvulso',
    component: () => import('@/views/LancamentoAvulso/LancamentoAvulsoModule.vue'),
    meta: { permissions },
    children: [
      {
        path: '/',
        component: () => import('@/views/LancamentoAvulso/LancamentoAvulsoList.vue'),
        meta: { permissions, title: 'Manutenção de Lançamentos Avulsos' },
      },
    ],
  })
}

export default {
  router,
}
