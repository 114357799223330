<template>
  <div>
    <v-menu
      bottom
      left
      offset-y
      style="border-radius: 20px;"
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          v-bind="attrs"
          v-on="on"
          @click="notOpened = false"
        >
          <v-badge
            :content="notificationQuantity"
            :value="notificationQuantity === 0 ? false : notOpened"
            color="success"
            overlap
            class="pa-1 ma-2"
            small
          >
            <v-icon
              small
              color="white"
              title="Acessar as mensagens do usuário!"
            >
              mdi-bell
            </v-icon>
          </v-badge>
        </div>
      </template>
      <v-list class="ma-0 pa-0">
        <v-toolbar
          color="blue darken-2"
          dark
          height="40px"
          class="ma-0 pa-0"
        >
          <v-icon
            small
            color="white"
            class="mx-2 px-0"
          >
            mdi-bell
          </v-icon>
          <v-toolbar-title
            class="px-0 pt-1"
          >
            Mensagens
          </v-toolbar-title>
        </v-toolbar>
        <v-list-item
          v-for="(item, i) in items"
          :key="i"
          :class="{active: item.active, normal: !item.active}"
          @click="item.action"
        >
          <v-list-item-title
            class="mx-2 px-0"
          >
            <v-icon
              color="#1976d2"
              class="mx-2 px-0"
            >
              {{ item.icon }}
            </v-icon>
            {{ item.title }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
  import UserProfileStore, { LOAD_NOTIFICATIONS } from '@/store/modules/userProfile'
  import { mapState, mapActions } from 'vuex'

  export default {
    name: 'Notification',
    data () {
      return {
        show: false,
        notOpened: true,
      }
    },
    computed: {
      ...mapState('userProfile', ['fornecedores', 'associados']),
      notificationQuantity () {
        return (this.fornecedores ? this.fornecedores.length : 0) + (this.associados ? this.associados.length : 0)
      },
      items () {
        const itens = []

        if (this.fornecedores?.length > 0) {
          itens.push({
            title: 'Você possui ' + this.fornecedores.length + ' fornecedores com lançamento e sem eventos configurados.',
            active: (this.fornecedores.length > 0),
            action: this.handleFornecedores,
            icon: 'mdi-account-supervisor',
          })
        }

        if (this.associados?.length > 0) {
          itens.push({
            title: 'Você possui ' + this.associados.length + ' associados com lançamento e sem eventos configurados.',
            active: (this.associados.length > 0),
            action: this.handleAssociados,
            icon: 'mdi-account-group',
          })
        }

        return itens.sort((a, b) => (a.active === b.active) ? 0 : a.active ? -1 : 1)
      },
    },
    created () {
      if (!this.$store.state.userProfile) { this.$store.registerModule('userProfile', UserProfileStore) }
      this.LOAD_NOTIFICATIONS()
    },
    methods: {
      ...mapActions('userProfile', [LOAD_NOTIFICATIONS]),
      handleFornecedores () {
        const cnpjs = []
        this.fornecedores.forEach(element => {
          cnpjs.push(element.CNPJ)
        })
        this.$router.push({ path: '/gestor/dashboard' }).catch(() => {})
        this.$router.push({ path: 'fornecedores/?cnpjs=' + JSON.stringify(cnpjs) }).catch(() => {})
      },
      handleAssociados () {
        const cnpjs = []
        this.associados.forEach(element => {
          cnpjs.push(element.CNPJ)
        })
        this.$router.push({ path: '/gestor/dashboard' }).catch(() => {})
        this.$router.push({ path: 'associados/?cnpjs=' + JSON.stringify(cnpjs) }).catch(() => {})
      },
    },
  }
</script>

<style scoped>
.normal {
  background: #ffffff;
  border-color: #9c9c9c;
}
.active {
  background: #f1f1ff;
}

.v-menu__content {
  border-radius: 20px;
  border: 7px solid;
  border-color:#1976d2;
}
</style>
