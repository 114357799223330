import { isFuture } from 'date-fns'

export const validarCNPJ = cnpj => {
  if (!cnpj) {
    return false
  }

  try {
    cnpj = cnpj.replace(/[^\d]+/g, '')
    if (!cnpj || cnpj === '') return false
    if (cnpj.length !== 14) {
      return false
    }
    // Elimina CNPJs invalidos conhecidos
    if (
      cnpj === '00000000000000' ||
      cnpj === '11111111111111' ||
      cnpj === '22222222222222' ||
      cnpj === '33333333333333' ||
      cnpj === '44444444444444' ||
      cnpj === '55555555555555' ||
      cnpj === '66666666666666' ||
      cnpj === '77777777777777' ||
      cnpj === '88888888888888' ||
      cnpj === '99999999999999'
    ) {
      return false
    }
    // Valida DVs
    var tamanho = cnpj.length - 2
    var numeros = cnpj.substring(0, tamanho)
    var digitos = cnpj.substring(tamanho)
    var soma = 0
    var pos = tamanho - 7
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--
      if (pos < 2) {
        pos = 9
      }
    }
    var resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)
    if (resultado !== parseInt(digitos.charAt(0))) {
      return false
    }
    tamanho = tamanho + 1
    numeros = cnpj.substring(0, tamanho)
    soma = 0
    pos = tamanho - 7
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--
      if (pos < 2) {
        pos = 9
      }
    }
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)
    if (resultado !== parseInt(digitos.charAt(1))) {
      return false
    }
    return true
  } catch {
    return false
  }
}

export const validarCPF = strCPF => {
  var Soma
  var Resto
  Soma = 0

  if (!strCPF) {
    return false
  }

  try {
    strCPF = strCPF.replace(/[^\d]+/g, '')
    if (strCPF === '00000000000' || !strCPF || strCPF.length < 11) return false

    for (let i = 1; i <= 9; i++) {
      Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i)
    }
    Resto = (Soma * 10) % 11

    if (Resto === 10 || Resto === 11) Resto = 0
    if (Resto !== parseInt(strCPF.substring(9, 10))) return false

    Soma = 0
    for (let i = 1; i <= 10; i++) {
      Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i)
    }
    Resto = (Soma * 10) % 11

    if (Resto === 10 || Resto === 11) Resto = 0
    if (Resto !== parseInt(strCPF.substring(10, 11))) return false
    return true
  } catch {
    return false
  }
}

export const validarData = strDate => {
  if (!strDate) {
    return false
  }

  const f1 = strDate.split('/')
  const dd = f1[0]
  const mm = f1[1]
  const yy = f1[2]
  const mn_dias = '0-31-' + (yy % 4 === 0 ? 29 : 28) + '-31-30-31-30-31-31-30-31-30-31'
  const dias = mn_dias.split('-')
  if ((f1.length !== 3 || mm.length === 2 || dd.length === 2 || yy.length === 4) && ((parseInt(mm) >= 1 && parseInt(mm) <= 12) && (parseInt(yy) >= parseInt(1900) && parseInt(yy) <= parseInt(2200))) && (parseInt(dd) >= 1 && parseInt(dd) <= parseInt(dias[parseInt(mm)]))) {
    return true
  } else {
    return false
  }
}

export const validarDataMes = strDate => {
  if (!strDate) {
    return false
  }

  const f1 = strDate.split('/')
  const mm = f1[0]
  const yy = f1[1]
  if ((f1.length !== 3 || mm.length === 2 || yy.length === 4) && ((parseInt(mm) >= 1 && parseInt(mm) <= 12) && (parseInt(yy) >= parseInt(1900) && parseInt(yy) <= parseInt(2200)))) {
    return true
  } else {
    return false
  }
}

export const data_Minima = (date, min) => {
  if (!validarData(date)) { return false }

  if (min !== '9999-99-99' && min !== '0000-00-00' && min !== null && min !== undefined) {
    const Array1 = date.split('/')
    const Array2 = min.substring(0, 10).split('-')
    const date1 = Array1[2] + Array1[1] + Array1[0]
    const date2 = Array2[0] + Array2[1] + Array2[2]

    if (date1 >= date2) { return true } else { return false }
  }

  return true
}

export const data_Maxima = (date, max) => {
  if (!validarData(date)) { return false }

  if (max !== '9999-99-99' && max !== '0000-00-00' && max !== null && max !== undefined) {
    const Array1 = date.split('/')
    const Array2 = max.substring(0, 10).split('-')
    const date1 = Array1[2] + Array1[1] + Array1[0]
    const date2 = Array2[0] + Array2[1] + Array2[2]

    if (date1 <= date2) { return true } else { return false }
  }

  return true
}

export const isNotFuture = strDate => {
  const [day, month, year] = strDate.split('/')

  return isFuture(new Date(month + '/' + day + '/' + year))
}
