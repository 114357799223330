import profile from './profile'
import keys from './keys'
import fornecedores from './fornecedores'
import associados from './associados'
import lancamentoFaturas from './lancamentoFaturas'
import lancamentoAvulsos from './lancamentoAvulsos'
import calculos from './calculos'
import extratoFornecedor from './extratoFornecedor'
import extratoAssociado from './extratoAssociado'
import eventos from './eventos'

const permissions = [
  {
    role: 'admin',
    access: false,
    redirect: 'admin',
  },
  {
    role: 'guest',
    access: false,
    redirect: 'login',
  },
]

/* ACESSO GESTOR */
export default {
  name: 'gestor',
  path: '/gestor',
  redirect: '/gestor/dashboard',
  component: () => import('@/views/Index'),
  meta: { requiresAuth: true, permissions },
  children: [
    {
      path: 'dashboard',
      component: () => import('@/views/dashboard/DashboardGestor'),
      meta: { permissions, title: 'Início' },
    },
    profile.router(permissions),
    fornecedores.router(permissions),
    associados.router(permissions),
    keys.router(permissions),
    lancamentoFaturas.router(permissions),
    fornecedores.router(permissions),
    associados.router(permissions),
    eventos.router(permissions),
    calculos.router(permissions),
    extratoFornecedor.router(permissions),
    extratoAssociado.router(permissions),
    lancamentoAvulsos.router(permissions),
  ],
}
