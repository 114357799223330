<template>
  <v-menu
    v-model="dateDialog"
    :close-on-content-click="true"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-if="!mesAno"
        ref="datafield"
        v-mask="'##/##/####'"
        :label="label"
        prepend-inner-icon="mdi-calendar"
        height="43"
        color="primary"
        outlined
        hide-details="false"
        dense
        rounded
        elevation="2"
        style="width: 158px!important"
        :rules="[rules.required,rules.validDate]"
        :value="displayData"
        maxlength="10"
        @click:prepend-inner="on.click"
        @change="handleChange"
      />
      <v-text-field
        v-else
        ref="datafield"
        v-mask="'##/####'"
        :label="label"
        prepend-inner-icon="mdi-calendar"
        height="43"
        color="primary"
        outlined
        rounded
        hide-details="false"
        dense
        elevation="2"
        style="width: 158px!important;"
        :rules="[rules.required,rules.validDateMes]"
        :value="displayData"
        maxlength="7"
        @click:prepend-inner="on.click"
        @change="handleChange"
      />
    </template>
    <v-date-picker
      v-if="!mesAno"
      v-model="selectedData"
      locale="pt-br"
      :min="dataMin"
      :max="dataMax"
      color="primary"
      no-title
      @input="handleInput"
    />
    <v-date-picker
      v-else
      v-model="selectedData"
      locale="pt-br"
      :min="dataMin"
      :max="dataMax"
      color="primary"
      no-title
      type="month"
      @input="handleInput"
    />
  </v-menu>
</template>

<script>
  import rules from '../../utils/formRules'
  import Swal from 'sweetalert2'

  export default {
    name: 'DataField',
    model: {
      prop: 'data',
      event: 'set',
    },
    props: {
      data: {
        type: String,
        default: '0000-00-00',
      },
      dataMin: {
        type: String,
        default: '0000-00-00',
      },
      dataMax: {
        type: String,
        default: '9999-99-99',
      },
      label: {
        type: String,
        default: 'Data',
      },
      mesAno: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        rules,
        selectedData: this.data,
        dateDialog: false,
      }
    },

    computed: {
      displayData () {
        if (!this.selectedData) {
          return ''
        }
        const data = new Date(this.selectedData)
        data.setTime(data.getTime() + data.getTimezoneOffset() * 60 * 1000)
        let dataFormatada = ''
        if (this.mesAno) {
          dataFormatada = this.adicionaZero(data.getMonth() + 1) + '/' + data.getFullYear()
        } else {
          dataFormatada = (this.adicionaZero(data.getDate())) + '/' + (this.adicionaZero(data.getMonth() + 1)) + '/' + data.getFullYear()
        }
        return dataFormatada
      },
    },

    methods: {
      adicionaZero (numero) {
        if (numero <= 9) {
          return '0' + numero
        } else {
          return numero
        }
      },
      handleInput (value) {
        this.selectedData = value
        this.$emit('set', value)
      },
      handleChange (value) {
        if (!this.$refs.datafield.validate()) { return null }

        const d1 = value.split('/')
        let dateMin2, dateMax2, sinal, novaData, novaDataFormat, d2

        if (this.mesAno) {
          const date1 = d1[1] + d1[0]
          d2 = d1[1] + '-' + d1[0]

          if (this.dataMin !== '9999-99' && this.dataMin !== '0000-00' && this.dataMin !== null && this.dataMin !== undefined) {
            const dateMin1 = this.dataMin.substring(0, 7).split('-')
            dateMin2 = dateMin1[0] + dateMin1[1]
          }

          if (this.dataMax !== '9999-99' && this.dataMax !== '0000-00' && this.dataMax !== null && this.dataMax !== undefined) {
            const dateMax1 = this.dataMax.substring(0, 7).split('-')
            dateMax2 = dateMax1[0] + dateMax1[1]
          }

          if (dateMin2 !== undefined || dateMax2 !== undefined) {
            if (dateMin2 !== undefined && dateMax2 !== undefined) {
              if (dateMax2 < dateMin2 && date1 < dateMax2) {
                const md = dateMax2
                novaData = md.substring(0, 4) + '-' + md.substring(4, 6)
                sinal = 'maior'
              } else if (dateMax2 >= dateMin2 && date1 < dateMin2) {
                const md = dateMin2
                novaData = md.substring(0, 4) + '-' + md.substring(4, 6)
                sinal = 'maior'
              } else if (dateMin2 > dateMax2 && date1 > dateMin2) {
                const md = dateMin2
                novaData = md.substring(0, 4) + '-' + md.substring(4, 6)
                sinal = 'menor'
              } else if (dateMin2 <= dateMax2 && date1 > dateMax2) {
                const md = dateMax2
                novaData = md.substring(0, 4) + '-' + md.substring(4, 6)
                sinal = 'menor'
              }
            } else if (dateMin2 !== undefined && date1 < dateMin2) {
              novaData = this.dataMin.substring(0, 7)
              sinal = 'maior'
            } else if (dateMax2 !== undefined && date1 > dateMax2) {
              novaData = this.dataMax.substring(0, 7)
              sinal = 'menor'
            }

            if (novaData !== undefined) {
              novaDataFormat = novaData.split('-')
              novaDataFormat = novaDataFormat[1] + '/' + novaDataFormat[0]
              this.selectedData = ''
              Swal.fire({
                icon: 'warning',
                title: 'Data Inválida!',
                text: `A data informada deve ser ${sinal} ou igual a ${novaDataFormat}!`,
              }).then((result) => {
                this.selectedData = novaData
                this.$emit('set', novaData)
                setTimeout(() => {
                  this.$refs.datafield.focus()
                }, 500)
              })
              return null
            }
          }
        } else {
          const date1 = d1[2] + d1[1] + d1[0]
          d2 = d1[2] + '-' + d1[1] + '-' + d1[0]

          if (this.dataMin !== '9999-99-99' && this.dataMin !== '0000-00-00' && this.dataMin !== null && this.dataMin !== undefined) {
            const dateMin1 = this.dataMin.substring(0, 10).split('-')
            dateMin2 = dateMin1[0] + dateMin1[1] + dateMin1[2]
          }

          if (this.dataMax !== '9999-99-99' && this.dataMax !== '0000-00-00' && this.dataMax !== null && this.dataMax !== undefined) {
            const dateMax1 = this.dataMax.substring(0, 10).split('-')
            dateMax2 = dateMax1[0] + dateMax1[1] + dateMax1[2]
          }

          if (dateMin2 !== undefined || dateMax2 !== undefined) {
            if (dateMin2 !== undefined && dateMax2 !== undefined) {
              if (dateMax2 < dateMin2 && date1 < dateMax2) {
                const md = dateMax2
                novaData = md.substring(0, 4) + '-' + md.substring(4, 6) + '-' + md.substring(6, 8)
                sinal = 'maior'
              } else if (dateMax2 >= dateMin2 && date1 < dateMin2) {
                const md = dateMin2
                novaData = md.substring(0, 4) + '-' + md.substring(4, 6) + '-' + md.substring(6, 8)
                sinal = 'maior'
              } else if (dateMin2 > dateMax2 && date1 > dateMin2) {
                const md = dateMin2
                novaData = md.substring(0, 4) + '-' + md.substring(4, 6) + '-' + md.substring(6, 8)
                sinal = 'menor'
              } else if (dateMin2 <= dateMax2 && date1 > dateMax2) {
                const md = dateMax2
                novaData = md.substring(0, 4) + '-' + md.substring(4, 6) + '-' + md.substring(6, 8)
                sinal = 'menor'
              }
            } else if (dateMin2 !== undefined && date1 < dateMin2) {
              novaData = this.dataMin.substring(0, 10)
              sinal = 'maior'
            } else if (dateMax2 !== undefined && date1 > dateMax2) {
              novaData = this.dataMax.substring(0, 10)
              sinal = 'menor'
            }

            if (novaData !== undefined) {
              novaDataFormat = novaData.split('-')
              novaDataFormat = novaDataFormat[2] + '/' + novaDataFormat[1] + '/' + novaDataFormat[0]
              this.selectedData = ''
              Swal.fire({
                icon: 'warning',
                title: 'Data Inválida!',
                text: `A data informada deve ser ${sinal} ou igual a ${novaDataFormat}!`,
              }).then((result) => {
                this.selectedData = novaData
                this.$emit('set', novaData)
                setTimeout(() => {
                  this.$refs.datafield.focus()
                }, 500)
              })
              return null
            }
          }
        }

        this.selectedData = d2
        this.$emit('set', d2)
      },
    },
  }
</script>
