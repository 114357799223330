<template>
  <div>
    <v-menu
      open-on-hover
      bottom
      center
      offset-y
      style="border-radius: 20px;"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          small
          color="white"
          title="Acessar o perfil do usuário"
          class="card-icon ma-2"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>
            mdi-account-cog-outline
          </v-icon>
        </v-btn>
      </template>
      <v-list class="ma-0 pa-0">
        <v-list-item
          :key="1"
          :class="{active: true}"
          @click="$router.push({ path: `/${$user.get().role}/profile` })"
        >
          <v-list-item-title>
            <v-icon
              small
            >
              mdi-account-edit-outline
            </v-icon>
            Dados Pessoais
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          :key="2"
          :class="{active: true}"
          @click="$router.push({ path: `/${$user.get().role}/keys` })"
        >
          <v-list-item-title>
            <v-icon
              small
            >
              mdi-account-key-outline
            </v-icon>
            Alterar Senha
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
  export default {
    name: 'MenuProfile',
  }
</script>

<style scoped>
.normal {
  background: #ffffff;
  border-color: #9c9c9c;
}
.active {
  background: #f1f1ff;
}

.v-menu__content {
  border-radius: 20px;
}
</style>
